import React from 'react'
import {
  SimpleMemberQuery,
  TimelineCardQuery,
  TimelineReactionMemberQuery,
} from '../../services'
import CardWrapper from './CardWrapper'
import MemberProfileCard from './MemberProfileCard'
import { useNavigate } from 'react-router-dom'
interface JoinCardProps {
  joinMember: SimpleMemberQuery
  timelineCard: TimelineCardQuery
  myTimelineReactionMemberQuery: TimelineReactionMemberQuery
  timelineCommentComponent?: JSX.Element
  onClickTimelineCard?: () => void
}

const JoinCard: React.FC<JoinCardProps> = ({
  timelineCard,
  joinMember,
  myTimelineReactionMemberQuery,
  timelineCommentComponent,
  onClickTimelineCard,
}) => {
  const navigate = useNavigate()
  return (
    <CardWrapper
      className={'home-timeline__card home-timeline__card-new-member'}
      onClick={onClickTimelineCard}
      profileImageUrl={joinMember.profileImageUrl}
      title={
        <>
          <span
            className="home-timeline__card-upper-title-span"
            onClick={event => {
              event.stopPropagation()
              navigate(`/member-detail/${joinMember.memberId}`)
            }}
          >
            {joinMember.memberName}
          </span>
          さんがparksに参加しました🎉
        </>
      }
      cardText={'プロフィールを確認してみましょう！'}
      createdAt={timelineCard.createdAt}
      timelineCard={timelineCard}
      myTimelineReactionMemberQuery={myTimelineReactionMemberQuery}
      member={joinMember}
      timelineCommentComponent={timelineCommentComponent}
    >
      <div className="home-timeline__card-under-wrapper">
        <MemberProfileCard
          member={joinMember}
          onClickTimelineCard={onClickTimelineCard}
        />
      </div>
    </CardWrapper>
  )
}

export default JoinCard
