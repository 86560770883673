import { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'
import { HeaderType } from '../../enums/HeaderType'
import {
  MemberControllerService,
  MemberCubeQuery,
  MentionTargetMember,
  TimelineCommentControllerService,
  TimelineControllerService,
  TimelineDetailQuery,
  TimelineReactionMemberQuery,
} from '../../services'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import JoinCard from '../../components/timeLine/JoinCard'
import UpdateSingleWordCard from '../../components/timeLine/UpdateSingleWordCard'
import UpdateCubeCard from '../../components/timeLine/UpdateCubeCard'
import { QuestionCard } from '../../components/question/questionCard'
import { TimelineComment } from '../../components/timeLine/TimelineComment'
import apiErrorHandler from '../../api/apiErrorHandler'
import { SESSION_KEY } from '../../constants/session'
import { answerTimelineDetailQuestion } from '../../lib/questionActions'

export const TimelineDetail: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const previousPagePath = location?.state?.from
  const path = location.pathname + location.search
  const urlParams = useParams<{ timelineId: string }>()
  const timelineId = Number(urlParams.timelineId)
  const [timelineDetail, setTimelineDetail] = useState<TimelineDetailQuery>()
  const [mentionTargetMembers, setMentionTargetMembers] = useState<
    MentionTargetMember[]
  >([])

  const [memberCubes, setMemberCubes] = useState<MemberCubeQuery[]>([])

  const [myTimelineReactionMemberQuery, setMyTimelineReactionMemberQuery] =
    useState({} as TimelineReactionMemberQuery)

  useEffect(() => {
    if (timelineId) {
      getTimelineDetail(timelineId)
      if (previousPagePath === SESSION_KEY.TIMELINE.PAGE_NAME) {
        sessionStorage.setItem(SESSION_KEY.TIMELINE.DETAIL_PATH_KEY, path)
      }
      if (previousPagePath === SESSION_KEY.QUESTION.PAGE_NAME) {
        sessionStorage.setItem(SESSION_KEY.QUESTION.DETAIL_PATH_KEY, path)
      }
      MemberControllerService.getMember(0)
        .then(res => {
          setMyTimelineReactionMemberQuery({
            memberId: res.memberId,
            memberName: res.memberName,
            profileImageUrl: res.profileImageUrl,
            positionName: res.position?.positionName,
          })
          setMemberCubes(res.cubes)
        })
        .catch(apiErrorHandler)
      getWorkspaceMembers()
    } else {
      navigate('/')
    }
  }, [])

  const getTimelineDetail = (timelineId: number) => {
    TimelineControllerService.getTimelineDetail(timelineId).then(res => {
      setTimelineDetail(res)
    })
  }

  const getWorkspaceMembers = () => {
    TimelineCommentControllerService.getMentionTargetMembers().then(res => {
      setMentionTargetMembers(res)
    })
  }

  const renderTimelineContent = () => {
    const timelineCard = timelineDetail?.timelineCard
    if (!timelineCard) {
      return
    }

    const answerQuestion = (questionId: number, choiceId: number) => {
      answerTimelineDetailQuestion(
        questionId,
        choiceId,
        timelineDetail,
        setTimelineDetail,
        apiErrorHandler,
      )
    }

    switch (timelineCard.timelineCardType) {
      case 'JOIN':
        // eslint-disable-next-line no-case-declarations
        const joinMember = timelineCard.joinCard?.member
        return (
          joinMember && (
            <JoinCard
              joinMember={joinMember}
              timelineCard={timelineCard}
              myTimelineReactionMemberQuery={myTimelineReactionMemberQuery}
              timelineCommentComponent={
                <TimelineComment
                  timelineId={timelineId}
                  timelineComments={timelineDetail?.timelineComments}
                  timelineCommentTotalCount={
                    timelineDetail.timelineComments.length
                  }
                  mentionTargetMembers={mentionTargetMembers}
                  isCompact={false}
                  isFixed={true}
                />
              }
            />
          )
        )

      case 'UPDATE_SINGLE_WORD':
        // eslint-disable-next-line no-case-declarations
        const updateSingleWordMember = timelineCard.updateSingleWordCard?.member
        return (
          updateSingleWordMember && (
            <UpdateSingleWordCard
              timelineCard={timelineCard}
              myTimelineReactionMemberQuery={myTimelineReactionMemberQuery}
              timelineCommentComponent={
                <TimelineComment
                  timelineId={timelineId}
                  timelineComments={timelineDetail?.timelineComments}
                  timelineCommentTotalCount={
                    timelineDetail.timelineComments.length
                  }
                  mentionTargetMembers={mentionTargetMembers}
                  isCompact={false}
                  isFixed={true}
                />
              }
            />
          )
        )
      case 'UPDATE_CUBE':
      case 'string': // mockの場合
        return (
          <>
            <UpdateCubeCard
              timelineCard={timelineCard}
              memberId={myTimelineReactionMemberQuery.memberId}
              memberCubes={memberCubes}
              index={timelineCard.timelineCardId}
              timelineCommentComponent={
                <TimelineComment
                  timelineId={timelineId}
                  timelineComments={timelineDetail?.timelineComments}
                  timelineCommentTotalCount={
                    timelineDetail.timelineComments.length
                  }
                  mentionTargetMembers={mentionTargetMembers}
                  isCompact={false}
                  isFixed={true}
                />
              }
            />
          </>
        )

      case 'QUESTION': {
        // eslint-disable-next-line no-case-declarations
        const question = timelineCard.questionCard?.question
        return (
          question && (
            <div className="timeline-detail__question home-timeline__card home-timeline__card-new-member">
              <QuestionCard
                key={question.questionId}
                question={question}
                activeFilter={'ALL'}
                answerQuestion={answerQuestion}
              />
              <TimelineComment
                timelineId={timelineId}
                timelineComments={timelineDetail?.timelineComments}
                timelineCommentTotalCount={
                  timelineDetail.timelineComments.length
                }
                mentionTargetMembers={mentionTargetMembers}
                isCompact={false}
                isFixed={true}
              />
            </div>
          )
        )
      }
    }
  }

  return (
    <>
      <Head />
      <Header headerType={HeaderType.DEFAULT} />
      <SideNavi />
      <main className="main-sidenav timeline-detail">
        <div className="timeline-detail__content">
          {renderTimelineContent()}
        </div>
      </main>
    </>
  )
}
