import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'
import { useNavigate, useParams } from 'react-router-dom'
import apiErrorHandler from '../../api/apiErrorHandler'
import Loading from 'react-loading'
import { MemberControllerService, MemberQuery } from '../../services'
import { getCareerInfo } from '../../lib/common'
import { useModal } from '../../contexts/modalContext'
import env from '../../config/env'
import { SafeParagraph } from '../../components/safeParagraph'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { Button } from '../../components/button'
import { CubeSendModal } from '../../modals/cubeSendModal'
import MemberList from '../../components/MemberList'
import { BasicInfo } from '../../components/BasicInfo'
import { CareerInfo } from '../../components/CareerInfo'
import { CubeList } from '../../components/cube/cubeList'
import ChatButton from '../../components/button/chatButton'

export const MemberDetail: FC = () => {
  const urlParams = useParams<{ memberId: string }>()
  const navigate = useNavigate()
  const { openModal } = useModal()

  const [member, setMember] = useState({} as MemberQuery)
  const [targetMember, setTargetMember] = useState({} as MemberQuery)
  const [careerInfo, setCareerInfo] = useState<string[]>([])
  const [recommendMembers, setRecommendMembers] = useState([] as MemberQuery[])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isNaN(Number(urlParams.memberId))) {
      navigate('/')
      return
    }

    MemberControllerService.getMember(0)
      .then(memberRes => {
        // ログインユーザの場合はプロフィール画面に遷移する
        if (
          env.ENV !== 'mock' &&
          memberRes.memberId === Number(urlParams.memberId)
        ) {
          navigate('/profile')
          return
        }
        setMember(memberRes)
        MemberControllerService.getMember(Number(urlParams.memberId))
          .then(targetMemberRes => {
            setTargetMember(targetMemberRes)
            setCareerInfo(getCareerInfo(targetMemberRes.careers))
            setIsLoading(false)

            // Firebase Analytics
            if (env.FIREBASE_API_KEY) {
              const analytics = getAnalytics()
              logEvent(analytics, 'member_detail_view', {
                loginMemberId: memberRes.memberId,
                memberId: targetMemberRes.memberId,
              })
            }
          })
          .catch(apiErrorHandler)
      })
      .catch(apiErrorHandler)

    MemberControllerService.getMembers(Number(urlParams.memberId), 'COMMON', 3)
      .then(res => {
        setRecommendMembers(res)
      })
      .catch(apiErrorHandler)
  }, [urlParams.memberId, openModal])

  return (
    <>
      <Head />
      <Header />
      <SideNavi />
      <main className="main-sidenav member_details_page-main-sidenav">
        {isLoading && (
          <Loading className="loading" type="spin" color="#007559" />
        )}
        {!isLoading && (
          <>
            <div className="member_details_page__head">
              <div className="member_details__flex-wrapper">
                <div className="member_details__card">
                  <div className="profile-card__img">
                    <img
                      src={
                        targetMember.profileImageUrl
                          ? targetMember.profileImageUrl
                          : '/icons/avatar-sample.png'
                      }
                      alt="プロフィール画像"
                    />
                  </div>

                  <div className="member_details__card__body">
                    <h2 className="profile-card__name">
                      {targetMember.memberName}
                    </h2>
                    <p className="profile-card__name-ruby">
                      {targetMember?.memberNameFurigana}
                    </p>
                    <div className="profile-card__position">
                      {targetMember.position?.positionName}
                    </div>
                    {careerInfo.length > 0 && (
                      <div className="profile-card__position">
                        {careerInfo.map((info, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && <br />}
                            {info}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                {/* ひとこと */}
                {targetMember.singleWord && (
                  <div className="profile_page__head-content">
                    <SafeParagraph
                      className="profile-card__singleWord"
                      content={targetMember.singleWord}
                    />
                  </div>
                )}
              </div>
              {/* spサイズひとこと */}
              {targetMember.singleWord && (
                <div className="profile_page-comment-content__wrapper">
                  <div className="profile_page-comment-content">
                    <SafeParagraph
                      className="profile_page__comment-text"
                      content={targetMember.singleWord}
                    />
                  </div>
                </div>
              )}

              <div className="member_details_page__head-btn-wrapper">
                <ChatButton
                  className="btn_secondary-l btn_member_details-dark"
                  memberId={targetMember.memberId}
                />
              </div>

              <div className="member_details_page__head-avatar">
                <img
                  src={
                    targetMember.profileImageUrl
                      ? targetMember.profileImageUrl
                      : '/icons/avatar-sample.png'
                  }
                  alt="プロフィール画像"
                />
              </div>
            </div>
            <div className="member_details_page-profile-block-sp">
              <h2 className="profile-card__name-sp">
                {targetMember.memberName}
              </h2>
              <p className="profile-card__name-ruby-sp">
                {targetMember?.memberNameFurigana}
              </p>
              <div className="profile-card__position-sp">
                {targetMember.position?.positionName}
              </div>
              {careerInfo.length > 0 && (
                <div className="profile-card__position-sp">
                  {careerInfo.map((info, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {info}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
            <div className="main_sidenav__inner member_details_page__inner">
              <div className="member_details_page__block">
                <div className="member_details_page__content">
                  <h3 className="member_details-page__lead">主な業務内容</h3>
                  <SafeParagraph
                    className="member_details__text"
                    content={targetMember.businessContent}
                  />
                </div>
              </div>
              <div className="member_details_page__block">
                <div className="member_details_page__content member_details_page__cube-content">
                  <CubeList
                    targetMember={targetMember}
                    member={member}
                    titleClassName="member_details-page__lead"
                    listClassName="member_details-cube__list"
                    onAddClick={() => {
                      openModal(CubeSendModal, {
                        memberId: targetMember.memberId,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="member_details_page__block member_details_page__block-flex">
                <div className="member_details__block-left">
                  <div className="member_details_page__content member_details_page__base-profile-content">
                    <BasicInfo
                      member={targetMember}
                      titleClassName="member_details-page__lead"
                      dateOfBirthShowFlg={targetMember.dateOfBirthShowFlg}
                    />
                  </div>

                  <div className="member_details_page__content member_details_page__career-content">
                    <CareerInfo
                      careers={targetMember.careers}
                      titleClassName="member_details-page__lead"
                    />
                  </div>
                </div>

                <div className="member_details__block-right">
                  <MemberList
                    targetMember={targetMember}
                    members={recommendMembers}
                    title="関連するメンバー"
                    linkTo={'/member-list?sort=COMMON'}
                  />
                  <div className="member_details_page-bottom-btn__wrapper u-pc-none-md">
                    <Button
                      className="btn btn_member_details-dark btn_cube-dark"
                      onClick={() =>
                        openModal(CubeSendModal, {
                          memberId: targetMember.memberId,
                        })
                      }
                    >
                      キューブを送る
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </main>
    </>
  )
}
