import { FC, useState } from 'react'
import { Button } from '../../components/button'
import {
  CubeControllerService,
  MemberCubeControllerService,
  MemberCubeForm,
  MemberCubesForm,
  MemberQuery,
} from '../../services'
import React from 'react'
import apiErrorHandler from '../../api/apiErrorHandler'
import { SuggestMemberCard } from '../../components/suggestMemberCard'
import { SuggestTextInput } from '../../components/suggestTextInput'
import { toast } from 'react-toastify'

interface Props {
  memberId?: number
  closeModal: () => void
  setIsLoading: ( isLoading:boolean) => void;
}

export const SendCubeForm: FC<Props> = ({ memberId, closeModal, setIsLoading }) => {
  const [modalPage, setModalPage] = useState<'SELECT' | 'EXPLAIN'>('SELECT')

  const [inputCubeMap, setInputCubeMap] = useState<Map<number, string>>(
    new Map(),
  )
  // チャットメンバー選択と同じコンポーネントを利用しているため配列で受け取る
  const [members, setMembers] = useState<MemberQuery[]>()
  const [cubeMap, setCubeMap] = useState<Map<number, string[]>>(new Map())

  // キューブごとに説明を設定するかどうか
  const [isIndividualExplanation, setIsIndividualExplanation] = useState(false)
  // 説明（共通）
  const [giftMessage, setGiftMessage] = useState<string>('')
  // 説明（個別）
  const [giftMessageMap, setGiftMessageMap] = useState<Map<number, string[]>>(
    new Map(),
  )

  const goPage = (page: 'SELECT' | 'EXPLAIN') => {
    setModalPage(page)
  }

  // キューブ登録
  const createCubes = () => {
    if (members?.length !== 1) {
      return
    }
    const memberCubeForms = Array.from(cubeMap)
      .map(([cubeCategoryId, cubes]) => {
        return cubes.map((cubeName, index) => {
          return {
            memberId: members[0].memberId,
            cubeCategoryId: cubeCategoryId,
            cubeName: cubeName,
            giftMessage: isIndividualExplanation
              ? giftMessageMap.get(cubeCategoryId)?.[index]
              : giftMessage,
          } as MemberCubeForm
        })
      })
      .flat()
    setIsLoading(true)
    MemberCubeControllerService.createMemberCubes({
      memberCubes: memberCubeForms,
    } as MemberCubesForm)
      .then(() => {
        toast.success('登録しました')
        closeModal()
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }

  // cube入力
  const setInput = (cubeCategoryId: number, value: string) => {
    const newInputCubeMap = new Map(inputCubeMap)
    newInputCubeMap.set(cubeCategoryId, value)
    setInputCubeMap(newInputCubeMap)
  }

  // cube追加
  const setCube = (cubeCategoryId: number, value: string) => {
    // 空文字や同名の場合は追加しない
    if (
      !value ||
      !value.match(/\S/g) ||
      cubeMap.get(cubeCategoryId)?.includes(value)
    ) {
      return
    }

    const newCubeMap = new Map(cubeMap)
    newCubeMap.set(cubeCategoryId, [
      ...(newCubeMap.get(cubeCategoryId) || []),
      value,
    ])
    setCubeMap(newCubeMap)
  }

  // cube削除
  const deleteCube = (cubeCategoryId: number, index: number) => {
    const newCubeMap = new Map(cubeMap)
    const cubes = newCubeMap.get(cubeCategoryId)
    if (cubes) {
      cubes.splice(index, 1)
      newCubeMap.set(cubeCategoryId, cubes)
      setCubeMap(newCubeMap)
    }
  }

  // サジェスト候補を取得
  const getSuggests = async (value: string): Promise<string[]> => {
    return await CubeControllerService.suggestCubes(value)
      .then(res => {
        return res.map(cube => cube.cubeName)
      })
      .catch(err => {
        apiErrorHandler(err)
        return []
      })
  }

  return (
    <>
      <div className="send-cube__form">
        {modalPage === 'SELECT' && (
          <>
            <div className="career-edit__form">
              <div
                className={`${memberId && members?.length === 0 && 'hidden'}`}
              >
                <SuggestMemberCard
                  defaultMemberIds={
                    memberId
                      ? [memberId]
                      : members?.length === 1
                      ? [members[0].memberId]
                      : []
                  }
                  setMembers={setMembers}
                />
              </div>
              {members?.length === 1 && (
                <div className="form__item">
                  {members[0].cubeCategories.map((cubeCategory, index) => (
                    <React.Fragment key={index}>
                      <label className="form__label">
                        {cubeCategory.cubeCategoryName}
                      </label>
                      <div className="cube_input__block">
                        <SuggestTextInput
                          className="cube_input"
                          placeholder="キューブを入力"
                          value={
                            inputCubeMap.get(cubeCategory.cubeCategoryId) || ''
                          }
                          setValue={value =>
                            setInput(cubeCategory.cubeCategoryId, value)
                          }
                          onSelected={value => {
                            setCube(cubeCategory.cubeCategoryId, value)
                          }}
                          getSuggests={getSuggests}
                        />
                      </div>
                      <ul className="cube-list signup_cube__recommend-list">
                        {Array.from(cubeMap)
                          .filter(
                            ([cubeCategoryId]) =>
                              cubeCategoryId === cubeCategory.cubeCategoryId,
                          )
                          .map(([cubeCategoryId, cubes]) =>
                            cubes.map((cubeName, index) => (
                              <li className="cube" key={index}>
                                {cubeName}
                                <Button
                                  className="cube__delete-btn"
                                  onClick={() =>
                                    deleteCube(cubeCategoryId, index)
                                  }
                                />
                              </li>
                            )),
                          )}
                      </ul>
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
            <div className="modal-edit-btn__wrapper send-cube__btns">
              <Button className="btn_secondary-l" onClick={closeModal}>
                キャンセル
              </Button>
              <Button
                className="btn"
                onClick={() => {
                  goPage('EXPLAIN')
                }}
                disabled={members?.length !== 1 || cubeMap.size === 0}
              >
                次へ
              </Button>
            </div>
          </>
        )}
        {modalPage === 'EXPLAIN' && (
          <>
            <div className="career-edit__form">
              <label className="form__label">説明</label>
              {/* cubeMapの値が2つ以上なら表示 */}
              {Array.from(cubeMap)
                .map(([, cubes]) => cubes)
                .flat().length > 1 && (
                <div
                  className="form__item setting__detail-item"
                  style={{ marginTop: 0 }}
                >
                  <input
                    type="checkbox"
                    id="isCommonExplanation"
                    name="isCommonExplanation"
                    checked={isIndividualExplanation}
                    onChange={() =>
                      setIsIndividualExplanation(!isIndividualExplanation)
                    }
                  />
                  <label htmlFor="isCommonExplanation">
                    キューブごとに設定する
                  </label>
                </div>
              )}
              {isIndividualExplanation ? (
                // キューブごとに説明を設定する場合
                <div className="form__item">
                  {Array.from(cubeMap).map(([cubeCategoryId, cubes]) =>
                    cubes.map((cubeName, index) => (
                      <React.Fragment key={index}>
                        <label className="form__label">
                          {members
                            ? members[0]?.cubeCategories.find(
                                category =>
                                  category.cubeCategoryId === cubeCategoryId,
                              )?.cubeCategoryName
                            : ''}
                        </label>
                        <ul className="cube-list signup_cube__recommend-list">
                          <li className="cube cube-disabled">{cubeName}</li>
                        </ul>
                        <label className="form__label">
                          <textarea
                            className="form__textarea"
                            value={giftMessageMap.get(cubeCategoryId)?.[index]}
                            placeholder="きっかけや理由などをコメントしましょう！（任意）"
                            onChange={e => {
                              const newExplanationMap = new Map(giftMessageMap)
                              const newExplanations =
                                newExplanationMap.get(cubeCategoryId)
                              if (newExplanations) {
                                newExplanations[index] = e.target.value
                                newExplanationMap.set(
                                  cubeCategoryId,
                                  newExplanations,
                                )
                              } else {
                                newExplanationMap.set(cubeCategoryId, [
                                  e.target.value,
                                ])
                              }
                              setGiftMessageMap(newExplanationMap)
                            }}
                            style={{ height: '10rem' }}
                          ></textarea>
                        </label>
                      </React.Fragment>
                    )),
                  )}
                </div>
              ) : (
                // 全てのキューブに共通の説明を設定する場合
                <div className="form__item">
                  <ul className="cube-list signup_cube__recommend-list">
                    {Array.from(cubeMap).map(([, cubes]) =>
                      cubes.map((cubeName, index) => (
                        <li className="cube cube-disabled" key={index}>
                          {cubeName}
                        </li>
                      )),
                    )}
                  </ul>
                  <label className="form__label">
                    <textarea
                      className="form__textarea"
                      value={giftMessage}
                      placeholder="きっかけや理由などをコメントしましょう！（任意）"
                      onChange={e => setGiftMessage(e.target.value)}
                      style={{ height: '10rem' }}
                    ></textarea>
                  </label>
                </div>
              )}
            </div>
            <div className="modal-edit-btn__wrapper send-cube__btns">
              <Button
                className="btn_secondary-l"
                onClick={() => {
                  goPage('SELECT')
                }}
              >
                戻る
              </Button>
              <Button
                className="btn"
                onClick={createCubes}
                disabled={members?.length !== 1 || cubeMap.size === 0}
              >
                キューブを送る
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}
