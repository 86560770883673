import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SimpleMemberQuery, TimelineCardQuery } from '../../services'
import { timeAgoInWords } from '../../lib/common'

interface GiftUpdateCubeCardProps {
  timelineCard: TimelineCardQuery
  index: number
  member: SimpleMemberQuery
  suffix: string
  giftMember: SimpleMemberQuery
  children: React.ReactNode
  timelineCommentComponent?: JSX.Element
  onClick?: () => void
}

const GiftUpdateCubeCard: React.FC<GiftUpdateCubeCardProps> = ({
  timelineCard,
  member,
  giftMember,
  index,
  suffix,
  children,
  timelineCommentComponent,
  onClick,
}) => {
  const navigate = useNavigate()
  const preventEventPropagation = (event: React.SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <li
      className={`home-timeline__card home-timeline__card-cube ${
        timelineCard?.unreadFlg ? 'home-timeline__card-is-unread' : ''
      }`}
      key={index}
      onClick={onClick}
    >
      <div
        className={`${
          onClick
            ? 'home-timeline__card-link--active'
            : 'home-timeline__card-link'
        }`}
      >
        <div className="home-timeline__send-cube">
          <div
            className="home-timeline__card-upper-avater"
            onClick={event => {
              preventEventPropagation(event)
              window.location.href = `/member-detail/${member.memberId}`
            }}
          >
            <img
              src={
                giftMember.profileImageUrl
                  ? giftMember.profileImageUrl
                  : '/icons/avatar-sample.png'
              }
              onClick={event => {
                preventEventPropagation(event)
                navigate(`/member-detail/${giftMember.memberId}`)
              }}
              alt="プロフィール画像"
              width={40}
              height={40}
            />
          </div>
          <svg
            width="6"
            height="4"
            viewBox="0 0 6 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={preventEventPropagation}
          >
            <rect x="0.499756" width="5" height="4" rx="2" fill="#C9CFD6" />
          </svg>
          <div className="home-timeline__card-cube_icon-send">
            <img
              src={'/images/icon_cube_home-send.svg'}
              onClick={preventEventPropagation}
              alt="プロフィール画像"
              width={15}
              height={14}
            />
          </div>
          <svg
            width="6"
            height="4"
            viewBox="0 0 6 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={preventEventPropagation}
          >
            <rect x="0.499756" width="5" height="4" rx="2" fill="#C9CFD6" />
          </svg>
          <div className="home-timeline__card-cube_icon-cube">
            <img
              src={'/images/icon_cube_home.svg'}
              onClick={preventEventPropagation}
              alt="プロフィール画像"
              width={60}
              height={60}
            />
          </div>
          <svg
            width="6"
            height="4"
            viewBox="0 0 6 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={preventEventPropagation}
          >
            <rect x="0.499756" width="5" height="4" rx="2" fill="#C9CFD6" />
          </svg>
          <div className="home-timeline__card-cube_icon-send">
            <img
              src={'/images/icon_cube_home-send.svg'}
              onClick={preventEventPropagation}
              alt="プロフィール画像"
              width={15}
              height={14}
            />
          </div>
          <svg
            width="6"
            height="4"
            viewBox="0 0 6 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={preventEventPropagation}
          >
            <rect x="0.499756" width="5" height="4" rx="2" fill="#C9CFD6" />
          </svg>
          <div className="home-timeline__card-upper-avater">
            <img
              src={
                member.profileImageUrl
                  ? member.profileImageUrl
                  : '/icons/avatar-sample.png'
              }
              onClick={() => {
                window.location.href = `/member-detail/${member.memberId}`
              }}
              alt="プロフィール画像"
              width={40}
              height={40}
            />
          </div>
        </div>
      </div>
      <div
        className="home-timeline__card-upper-content"
        onClick={() =>
          navigate(`/timeline-detail/${timelineCard.timelineCardId}`)
        }
      >
        <h2 className="home-timeline__card-upper-title">
          <span
            className="clickable-name"
            onClick={event => {
              preventEventPropagation(event)
              navigate(`/member-detail/${giftMember.memberId}`)
            }}
          >
            {giftMember.memberName}
          </span>
          さんが
          <span
            className="clickable-name"
            onClick={event => {
              preventEventPropagation(event)
              window.location.href = `/member-detail/${member.memberId}`
            }}
          >
            {member.memberName}
          </span>
          さんに{suffix}
        </h2>
        <span className="home-timeline__card-posttime">
          {timeAgoInWords(timelineCard.createdAt)}
        </span>
        {children}
      </div>
      {timelineCommentComponent}
    </li>
  )
}

export default GiftUpdateCubeCard
