import React, { useEffect, useState } from 'react'
import { Cube } from './index'
import {
  CubeCategoryQuery,
  MemberControllerService,
  MemberCubeControllerService,
  type MemberCubeCubeCategoryUpdateForm,
  MemberCubeQuery,
  MemberQuery,
} from '../../services'
import BtnWrapper from '../button/btnWrapper'
import { Button } from '../button'
import { useModal } from '../../contexts/modalContext'
import { AboutCubeModal } from '../../modals/aboutCubeModal'
import { CubeCategoryModal } from '../../modals/cubeCategoryModal'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { Droppable } from '../dnd/Droppable'
import { Draggable } from '../dnd/Draggable'
import { CubeDetailModal } from '../../modals/cubeDetailModal'
import apiErrorHandler from '../../api/apiErrorHandler'
import Loading from 'react-loading'

interface CubeListProps {
  targetMember: MemberQuery
  member?: MemberQuery
  titleClassName: string
  listClassName?: string
  onAddClick: React.MouseEventHandler<HTMLButtonElement>
  onExtractClick?: React.MouseEventHandler<HTMLButtonElement>
  isProfile?: boolean
}

export const CubeList: React.FC<CubeListProps> = ({
  targetMember,
  member,
  titleClassName,
  listClassName,
  onAddClick,
  onExtractClick,
  isProfile,
}) => {
  const { openModal } = useModal()
  const [isLoading, setIsLoading] = useState(false)
  const [targetMemberState, setTargetMemberState] =
    useState<MemberQuery>(targetMember)
  const [cubeMap, setCubeMap] = React.useState<
    Map<CubeCategoryQuery, MemberCubeQuery[]>
  >(new Map())
  const [reloadCount, setReloadCount] = React.useState(0)

  const [movedCube, setMovedCube] = React.useState<MemberCubeQuery>()
  const [movedCubeCategory, setMovedCubeCategory] =
    React.useState<CubeCategoryQuery>()

  useEffect(() => {
    if (reloadCount !== 0) {
      MemberControllerService.getMember(targetMember.memberId)
        .then(res => {
          setTargetMemberState(res)
          groupingCubes(res)
        })
        .catch(apiErrorHandler)
    } else {
      setTargetMemberState(targetMember)
      groupingCubes(targetMember)
    }
    setIsLoading(false)
  }, [targetMember, reloadCount])

  const groupingCubes = (targetMember: MemberQuery) => {
    // キューブをカテゴリーごとにグルーピング
    const newCubeMap = new Map<CubeCategoryQuery, MemberCubeQuery[]>()
    targetMember.cubeCategories?.forEach(cubeCategory => {
      newCubeMap.set(cubeCategory, [])
      targetMember.cubes?.forEach(cube => {
        if (cube.cubeCategory.cubeCategoryId === cubeCategory.cubeCategoryId) {
          newCubeMap.get(cubeCategory)?.push(cube)
        }
      })
    })
    setCubeMap(newCubeMap)
  }

  // ドラッグが終了したときの処理
  const onDragEnd = (event: DragEndEvent) => {
    const {
      active, // ドラッグ中の要素
      over, // ドロップ先の要素
      delta, // ドラッグ中の要素の移動量
    } = event
    if (over == null) {
      return
    }
    const memberCubeId = Number(active.id)
    const cubeCategoryId = Number(over.id)

    // 5px以上移動していない場合はクリックとみなす
    if (Math.abs(delta.x) < 5 && Math.abs(delta.y) < 5) {
      openModal(CubeDetailModal, {
        memberCubeId: memberCubeId,
        deleteButtonEnabled: true,
      })
      return
    }

    setIsLoading(true)

    // 移動したキューブ
    const movedCube = targetMemberState.cubes?.find(
      cube => cube.memberCubeId === memberCubeId,
    )
    // 移動したキューブのカテゴリー
    const movedCubeCategory = targetMemberState.cubeCategories?.find(
      category =>
        category.cubeCategoryId === movedCube?.cubeCategory.cubeCategoryId,
    )
    // 移動先のカテゴリー
    const targetCubeCategory = targetMemberState.cubeCategories?.find(
      category => category.cubeCategoryId === cubeCategoryId,
    )
    // 移動先のカテゴリーの同名キューブ
    const targetCategoryCube =
      targetCubeCategory &&
      cubeMap
        .get(targetCubeCategory)
        ?.find(cube => cube.cubeName === movedCube?.cubeName)

    // 移動先が同じカテゴリーの場合は処理を中断
    if (
      movedCubeCategory?.cubeCategoryId === targetCubeCategory?.cubeCategoryId
    ) {
      setIsLoading(false)
      return
    }

    if (movedCube && targetCubeCategory && movedCubeCategory) {
      if (targetCategoryCube) {
        // 移動先に同名のキューブが存在する場合は警告後に統合する
        if (
          !window.confirm(
            `「${movedCube.cubeName}」は既に「${targetCubeCategory.cubeCategoryName}」に存在します。統合しますか？\n※統合すると分割することはできません。`,
          )
        ) {
          setIsLoading(false)
          return
        }
        // 統合
        MemberCubeControllerService.mergeMemberCubeAtoB(
          movedCube.memberCubeId,
          targetCategoryCube.memberCubeId,
        )
          .then(() => {
            // 点滅させるキューブを指定
            setMovedCubeCategory(targetCubeCategory)
            setMovedCube(targetCategoryCube)
            // キューブのみリロード
            setReloadCount(reloadCount + 1)
          })
          .catch(apiErrorHandler)
      } else {
        // 移動
        MemberCubeControllerService.updateMemberCubeCubeCategory(memberCubeId, {
          cubeCategoryId,
        } as MemberCubeCubeCategoryUpdateForm)
          .then(() => {
            // 点滅させるキューブを指定
            setMovedCubeCategory(targetCubeCategory)
            setMovedCube(movedCube)
            // キューブのみリロード
            setReloadCount(reloadCount + 1)
          })
          .catch(apiErrorHandler)
      }
    }
  }

  return isLoading ? (
    <Loading className="loading" type="spin" color="#007559" />
  ) : (
    <div>
      <div className="profile-page__lead-wrapper">
        <h3
          className={titleClassName}
          tabIndex={0}
          onClick={() => {
            openModal(AboutCubeModal, {})
          }}
        >
          キューブ
          <svg
            width="30"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              all: 'unset',
              position: 'relative',
              top: '7px',
              cursor: 'pointer',
            }}
          >
            <path
              d="M9 1.6875C7.55373 1.6875 6.13993 2.11637 4.9374 2.91988C3.73486 3.72339 2.7976 4.86544 2.24413 6.20163C1.69067 7.53781 1.54586 9.00811 1.82801 10.4266C2.11017 11.8451 2.80661 13.148 3.82928 14.1707C4.85196 15.1934 6.15492 15.8898 7.57341 16.172C8.99189 16.4541 10.4622 16.3093 11.7984 15.7559C13.1346 15.2024 14.2766 14.2651 15.0801 13.0626C15.8836 11.8601 16.3125 10.4463 16.3125 9C16.3105 7.06123 15.5394 5.20246 14.1685 3.83154C12.7975 2.46063 10.9388 1.68955 9 1.6875ZM9 13.5C8.83313 13.5 8.66999 13.4505 8.53124 13.3578C8.39249 13.2651 8.28434 13.1333 8.22048 12.9791C8.15662 12.825 8.13991 12.6553 8.17247 12.4916C8.20502 12.328 8.28538 12.1776 8.40338 12.0596C8.52138 11.9416 8.67172 11.8613 8.8354 11.8287C8.99907 11.7962 9.16872 11.8129 9.32289 11.8767C9.47707 11.9406 9.60884 12.0487 9.70156 12.1875C9.79427 12.3262 9.84375 12.4894 9.84375 12.6562C9.84375 12.88 9.75486 13.0946 9.59662 13.2529C9.43839 13.4111 9.22378 13.5 9 13.5ZM9.5625 10.0744V10.125C9.5625 10.2742 9.50324 10.4173 9.39775 10.5227C9.29226 10.6282 9.14919 10.6875 9 10.6875C8.85082 10.6875 8.70775 10.6282 8.60226 10.5227C8.49677 10.4173 8.4375 10.2742 8.4375 10.125V9.5625C8.4375 9.41332 8.49677 9.27024 8.60226 9.16475C8.70775 9.05926 8.85082 9 9 9C9.93024 9 10.6875 8.36719 10.6875 7.59375C10.6875 6.82031 9.93024 6.1875 9 6.1875C8.06977 6.1875 7.3125 6.82031 7.3125 7.59375V7.875C7.3125 8.02418 7.25324 8.16726 7.14775 8.27275C7.04226 8.37824 6.89919 8.4375 6.75 8.4375C6.60082 8.4375 6.45775 8.37824 6.35226 8.27275C6.24677 8.16726 6.1875 8.02418 6.1875 7.875V7.59375C6.1875 6.19805 7.44891 5.0625 9 5.0625C10.5511 5.0625 11.8125 6.19805 11.8125 7.59375C11.8125 8.81578 10.845 9.83883 9.5625 10.0744Z"
              fill="#007559"
            />
          </svg>
        </h3>
        {onExtractClick ? (
          <BtnWrapper
            displayType="pc"
            onCategoryClick={() => {
              openModal(CubeCategoryModal, {
                cubeCategories: targetMember.cubeCategories,
              })
            }}
            onAddClick={onAddClick}
            onExtractClick={onExtractClick}
          />
        ) : (
          <Button
            className="btn_secondary-l btn_cube u-sp-none-md"
            onClick={onAddClick}
          >
            キューブを送る
          </Button>
        )}
      </div>
      {isProfile && (
        <div className="profile-page__subtext">
          キューブはドラッグアンドドロップで移動できます
        </div>
      )}

      <DndContext onDragEnd={onDragEnd}>
        {Array.from(cubeMap).map(([cubeCategory, cubes]) => (
          <Droppable
            key={cubeCategory.cubeCategoryId}
            id={cubeCategory.cubeCategoryId}
          >
            <h2
              className={`cube__category ${
                cubeCategory.defaultFlg ||
                member?.memberId !== targetMember?.memberId
                  ? ''
                  : 'form__label-edit'
              }`}
              onClick={() => {
                if (!isProfile || cubeCategory.defaultFlg) {
                  return
                }
                openModal(CubeCategoryModal, {
                  cubeCategoryId: cubeCategory.cubeCategoryId,
                  cubeCategories: targetMember.cubeCategories,
                })
              }}
            >
              {cubeCategory.cubeCategoryName}
            </h2>
            <ul className={`${listClassName} ${listClassName}-sp`}>
              {cubes.map(cube =>
                isProfile ? (
                  <Draggable
                    key={cube.cubeId}
                    id={cube.memberCubeId}
                    moved={
                      movedCubeCategory?.cubeCategoryId ===
                        cubeCategory.cubeCategoryId &&
                      movedCube?.memberCubeId === cube.memberCubeId
                    }
                  >
                    <Cube
                      memberId={targetMember.memberId}
                      cube={cube}
                      key={cube.cubeId}
                      deleteButtonEnabled={isProfile}
                    />
                  </Draggable>
                ) : (
                  <Cube
                    memberId={targetMember.memberId}
                    cube={cube}
                    key={cube.cubeId}
                    deleteButtonEnabled={
                      cube.giftMembers.length > 0 &&
                      cube.giftMembers[0]?.memberId === member?.memberId
                    }
                  />
                ),
              )}
            </ul>
          </Droppable>
        ))}
      </DndContext>

      {onExtractClick ? (
        <BtnWrapper
          displayType="sp"
          onCategoryClick={() => {
            openModal(CubeCategoryModal, {
              cubeCategories: targetMember.cubeCategories,
            })
          }}
          onAddClick={onAddClick}
          onExtractClick={onExtractClick}
        />
      ) : (
        <>
          {isProfile && (
            <>
              <p className="signup_cube__category-info">
                「興味・関心」「スキル・専門」「パーソナリティ」以外にも自分でカテゴリーを追加することができます
              </p>
              <div className="btn-cube-sp__wrapper u-pc-none-md">
                <Button
                  className="btn__cube-sp"
                  onClick={() => {
                    openModal(CubeCategoryModal, {
                      cubeCategories: member?.cubeCategories,
                    })
                  }}
                >
                  カテゴリーを追加
                </Button>
              </div>
            </>
          )}
          <div className="btn-cube-sp__wrapper u-pc-none-md">
            <Button className="btn__cube-sp" onClick={onAddClick}>
              キューブを送る
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
