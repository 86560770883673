import React, { FC } from 'react'
import { QuestionQuery, ChoiceQuery } from '../../services'
import { useNavigate } from 'react-router-dom'
import { formatDate } from '../../lib/common'

interface QuestionCardProps {
  question: QuestionQuery
  activeFilter: string
  answerQuestion: (questionId: number, choiceId: number) => void
  deleteQuestion?: (questionId: number) => void
  onShare?: (question: QuestionQuery) => void
  onMoreViewCommentClick?: (timelineId: number) => void
  developerMode?: boolean
}

const getPercentage = (question: QuestionQuery, choice: ChoiceQuery) => {
  return question.totalAnswerCount !== 0
    ? Math.round((choice.answerCount / question.totalAnswerCount) * 1000) / 10 // 少数第一位まで表示
    : 0
}

export const QuestionCard: FC<QuestionCardProps> = ({
  question,
  activeFilter,
  answerQuestion,
  deleteQuestion,
  onShare,
  onMoreViewCommentClick,
  developerMode = false,
}) => {
  const navigate = useNavigate()
  const onClickCard = () => {
    if (onMoreViewCommentClick) {
      onMoreViewCommentClick(question.timelineInfo.timelineId)
    }
  }

  return (
    // question_tab-content-item__newクラスで新着の質問を背景色変更
    <li
      onClick={onClickCard}
      className={`
        question_tab-content-item
        ${
          question.timelineInfo.unreadFlg
            ? 'question_tab-content-item__new'
            : ''
        }
        `}
      key={activeFilter + question.questionId}
    >
      <span className="question-tab-content-time">
        {formatDate(question.createdAt, 'yyyy/MM/dd HH:mm')}
      </span>
      <div className="question_card">
        <div className="question_card-comment">
          <p>{question.question}</p>
        </div>

        {question.anonymousFlg ? (
          // 匿名の場合
          <div className="home-timeline__card-under-member question_card-member">
            <div className="clickable-img home-timeline__card-under-avater">
              <img
                src={'/icons/avatar-sample.png'}
                alt="プロフィール画像"
                width={64}
                height={64}
              />
            </div>
          </div>
        ) : question.member ? (
          // メンバーが存在する場合（通常ユーザ）
          <div className="home-timeline__card-under-member question_card-member">
            <div
              className="clickable-img home-timeline__card-under-avater"
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                navigate(`/member-detail/${question.member?.memberId}`)
              }}
            >
              <img
                src={
                  (!question.anonymousFlg &&
                    question.member?.profileImageUrl) ||
                  '/icons/avatar-sample.png'
                }
                alt="プロフィール画像"
                width={64}
                height={64}
              />
            </div>
            {!question.anonymousFlg && question.member && (
              <div className="home-timeline__card-under-profile">
                <div
                  className="clickable-name home-timeline__card-under-name"
                  onClick={event => {
                    event.preventDefault()
                    event.stopPropagation()
                    navigate(`/member-detail/${question.member?.memberId}`)
                  }}
                >
                  {question.member.memberName}
                </div>
                <div className="home-timeline__card-under-position">
                  {question.member.position?.positionName}
                </div>
              </div>
            )}
          </div>
        ) : (
          // メンバーが存在しない場合（公式アカウント）
          <div className="home-timeline__card-under-member question_card-member">
            <div className="clickable-img home-timeline__card-under-avater">
              <img
                src={'/icons/avatar-parks.png'}
                alt="プロフィール画像"
                width={64}
                height={64}
              />
            </div>
            <div className="home-timeline__card-under-profile">
              <div className="clickable-name home-timeline__card-under-name">
                parks
              </div>
            </div>
          </div>
        )}

        {question.answeredChoiceId ? (
          // 回答済みの場合
          <div className="question_card-result">
            <ul className="question_card-result-list">
              {question.choices.map(choice => (
                <li className="question_card-result-item" key={choice.choiceId}>
                  <div className="question_card-result-graph-wrapper">
                    {/* keyframesをReactで調整すると処理が複雑になるため、親要素でグラフの長さを調整 */}
                    {/* グラフの長さはwidthで変更 */}
                    <span
                      className="question_card-result-graph-area"
                      style={{
                        width: `${getPercentage(question, choice)}%`,
                      }}
                    >
                      {/* アニメーション用 */}
                      {/* is-selectedクラスでグラフの色を変える */}
                      <span
                        className={`question_card-result-graph ${
                          question.answeredChoiceId === choice.choiceId
                            ? 'is-selected'
                            : ''
                        }`}
                      ></span>
                    </span>
                    <p className="question_card-result-name">{choice.choice}</p>
                  </div>
                  <p>
                    <span className="question_card-result-number">
                      {getPercentage(question, choice)}
                    </span>
                    %
                  </p>
                </li>
              ))}
            </ul>
            <p className="question_card-result-votes">
              投票数：<span>{question.totalAnswerCount}</span>
            </p>
          </div>
        ) : (
          <ul className="question_card-questionnaire-list">
            {question.choices.map(choice => (
              <li
                className="question_card-questionnaire-item"
                key={choice.choiceId}
                onClick={event => {
                  event.stopPropagation()
                  answerQuestion(question.questionId, choice.choiceId)
                }}
              >
                {choice.choice}
              </li>
            ))}
          </ul>
        )}
      </div>
      {question.allowDelete && deleteQuestion && (
        <button
          type="button"
          className="question_tab-content-delete"
          onClick={event => {
            event.stopPropagation()
            deleteQuestion(question.questionId)
          }}
        >
          質問を削除する
        </button>
      )}
      {developerMode && onShare && (
        <button
          type="button"
          className="question_tab-content-delete"
          onClick={event => {
            event.stopPropagation()
            onShare(question)
          }}
        >
          他のワークスペースに共有する
        </button>
      )}
      {onMoreViewCommentClick && (
        <button
          type="button"
          className="question_tab-content-btn"
          onClick={() => {
            onMoreViewCommentClick(question.timelineInfo.timelineId)
          }}
        >
          {question.timelineInfo.commentCount > 0 ? (
            <>
              みんなのコメントを見る（
              <span>{question.timelineInfo.commentCount}</span>件）
            </>
          ) : (
            'コメントする'
          )}
        </button>
      )}
    </li>
  )
}
