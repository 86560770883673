import React, { FC, useEffect } from 'react'

import { Button } from '../button'
import Loading from 'react-loading'
import { pickupCubes } from '../../lib/common'
import react from 'react'
import { useDebounce } from '../../hooks/useDebounce'
import {
  MemberControllerService,
  MemberQuery,
  MemberSearchControllerService,
  SearchQuery,
} from '../../services'
import env from '../../config/env'

interface Props {
  defaultMemberIds?: number[]
  setMembers: (members: MemberQuery[]) => void
  multiSelect?: boolean
}

export const SuggestMemberCard: FC<Props> = ({
  defaultMemberIds,
  setMembers,
  multiSelect,
}) => {
  const [isLoading, setIsLoading] = react.useState(false)
  const [disabled, setDisabled] = react.useState(false)

  const SORT = 'COMMON'
  const searchMemberDebounce = useDebounce(500)
  const [search, setSearch] = react.useState({} as SearchQuery)
  const [selectedMembers, setSelectedMembers] = react.useState<MemberQuery[]>(
    [],
  )

  const searchMembers = (keyword: string) => {
    if (keyword.length < 1) {
      return
    }

    searchMemberDebounce(() => {
      setIsLoading(true)
      MemberSearchControllerService.searchMembers(
        false,
        keyword,
        undefined,
        SORT,
        1,
        20,
      )
        .then(res => {
          setSearch(res)
          // mockの場合は表示数を増やす
          if (env.ENV === 'mock') {
            const member = res.members[0]
            const member1 = {
              ...member,
              memberId: 1,
            }
            const member2 = {
              ...member,
              memberId: 2,
            }
            setSearch({
              ...res,
              members: [member1, member2],
            })
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    })
  }

  const deleteMember = (member: MemberQuery) => {
    setSelectedMembers(
      selectedMembers.filter(x => x.memberId !== member.memberId),
    )
  }

  const checkMemberId = (member: MemberQuery) => {
    const containMemberId = selectedMembers.some(
      x => x.memberId === member.memberId,
    )
    if (containMemberId) {
      const members = selectedMembers.filter(
        x => x.memberId !== member.memberId,
      )
      setSelectedMembers(members)
      return
    }
    if (multiSelect) {
      setSelectedMembers([...selectedMembers, member])
    } else {
      setSelectedMembers([member])
    }
  }

  useEffect(() => {
    if (defaultMemberIds) {
      defaultMemberIds.forEach(memberId => {
        MemberControllerService.getMember(memberId).then(res => {
          setSelectedMembers([...selectedMembers, res])
        })
      })
    }
  }, [])

  useEffect(() => {
    setMembers(selectedMembers)
    if (!multiSelect) {
      if (selectedMembers.length === 0) {
        setDisabled(false)
      } else {
        setSearch({
          ...search,
          members: selectedMembers,
        })
        setDisabled(true)
      }
    }
  }, [selectedMembers])

  return (
    <>
      <div className="form__item">
        <label className={`form__label ${disabled && 'form__label-disabled'}`}>
          メンバー
          <input
            type="text"
            placeholder="メンバー名やキーワードで検索"
            onChange={e => searchMembers(e.target.value)}
            disabled={disabled}
          />
        </label>
      </div>
      {selectedMembers.length !== 0 && multiSelect && (
        <ul className="modal-tag-list">
          <p>追加するメンバー: </p>
          {selectedMembers.map(selectedMember => (
            <li key={selectedMember.memberId}>
              {selectedMember.memberName}
              <Button
                className="modal-tag-list__delete-btn"
                onClick={() => deleteMember(selectedMember)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  aria-label="Close modal"
                >
                  <rect width="24" height="24" rx="12" fill="#FFFFFF" />
                  <path
                    d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
                    fill="#686C78"
                  />
                </svg>
              </Button>
            </li>
          ))}
        </ul>
      )}
      {search.members?.length !== undefined && (
        <div className="modal-edit__list">
          <ul className="search_input-page__list">
            {isLoading ? (
              <Loading className="loading" type="spin" color="#007559" />
            ) : (
              <>
                {search.members?.length === 0 && (
                  <>メンバーが見つかりませんでした</>
                )}
                {search.members?.map(member => (
                  <>
                    <li className="search_input-page-list__item">
                      <div
                        className={`card_list-m card_list-flex ${
                          selectedMembers.some(
                            x => x.memberId === member.memberId,
                          )
                            ? 'isChecked'
                            : ''
                        }`}
                        onClick={() => checkMemberId(member)}
                      >
                        <input
                          type="checkbox"
                          className="card_list__check"
                          checked={selectedMembers.some(
                            x => x.memberId === member.memberId,
                          )}
                        />
                        <div>
                          <div className="card_list-m__head">
                            <div className="card_list-m__profile-wrapper">
                              <div className="card_list-m_avatar">
                                <img
                                  src={
                                    member.profileImageUrl
                                      ? member.profileImageUrl
                                      : '/icons/avatar-sample.png'
                                  }
                                  alt="プロフィール画面"
                                />
                              </div>
                              <div className="card_list-m__profile">
                                <div className="card_list-m_name__wrapper">
                                  <h3 className="card_list-m_name">
                                    {member.memberName}
                                  </h3>
                                  <p className="card_list-m_year_of_service">
                                    {member.lengthOfService}
                                  </p>
                                </div>
                                <p className="card_list-m__position">
                                  {member.position?.positionName}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="card_list-m__body">
                            <ul className="card_list-m__list">
                              {pickupCubes(member.cubes, 15).map(cube => (
                                <>
                                  <li className="card_list-m__item">
                                    <Button className="cube-m">
                                      {cube.cubeName}
                                    </Button>
                                  </li>
                                </>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  </>
                ))}
              </>
            )}
          </ul>
        </div>
      )}
    </>
  )
}
