import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { QuestionQuery } from '../../services'
import { QuestionCard } from './questionCard'

interface QuestionListProps {
  questions: QuestionQuery[]
  title: string
  linkTo?: string
  answerQuestion: (questionId: number, choiceId: number) => void
  deleteQuestion: (questionId: number) => void
}

const QuestionCardList: React.FC<QuestionListProps> = ({
  questions,
  title,
  linkTo,
  answerQuestion,
  deleteQuestion,
}) => {
  const navigate = useNavigate()
  const onMoreViewCommentClick = (timelineId: number) => {
    const timelineDetailPath = `/timeline-detail/${timelineId}`
    navigate(timelineDetailPath, { state: { from: 'question' } })
  }
  return (
    <div className="question-card-list-wrapper">
      <div className="question-page_header-wrapper">
        <h3 className="question_details-page__lead">{title}</h3>
        {linkTo && (
          <Link to={linkTo} className="home-sidebar__more-link">
            一覧へ
          </Link>
        )}
      </div>
      <ul className="questions__list">
        {questions.map(question => (
          <QuestionCard
            key={question.questionId}
            question={question}
            activeFilter={'ALL'}
            answerQuestion={answerQuestion}
            deleteQuestion={deleteQuestion}
            onMoreViewCommentClick={onMoreViewCommentClick}
          />
        ))}
      </ul>
    </div>
  )
}

export default QuestionCardList
