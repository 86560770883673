import { FC, useState, useEffect } from 'react'
import { Button } from '../../components/button'
import { QuestionControllerService } from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'
interface Props {
  closeModal: () => void
  setIsLoading: (isLoading: boolean) => void
}

export const QuestionForm: FC<Props> = ({ closeModal, setIsLoading }) => {
  // 質問内容入力
  const [questionValue, setQuestionValue] = useState('')
  const handleQuestionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setQuestionValue(event.target.value)
  }

  // 回答の選択肢入力
  const [answerValues, setAnswerValues] = useState<string[]>(['', ''])
  const handleAnswerChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValues = [...answerValues]
    newValues[index] = event.target.value // 該当インデックスの値を更新
    setAnswerValues(newValues)
  }

  // 回答の選択肢追加
  const addAnswer = () => {
    if (answerValues.length < 4) {
      setAnswerValues([...answerValues, ''])
    }
  }

  // 匿名で質問するチェックボックス
  const [isAnonymous, setIsAnonymous] = useState(false)
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAnonymous(event.target.checked)
  }

  // 入力欄が入力済みか
  const [isEmptyForm, setIsEmptyForm] = useState(false)

  useEffect(() => {
    // 質問内容と選択肢(2件以上)が入力済みかを確認
    const hasTwoOrMoreAnswers =
      answerValues.filter(value => value.trim() !== '').length >= 2
    const isEmpty = questionValue.trim() === '' || !hasTwoOrMoreAnswers
    setIsEmptyForm(isEmpty)
  }, [questionValue, answerValues])

  const createQuestion = () => {
    const questionForm = {
      question: questionValue,
      choices: answerValues.filter(answer => answer !== ''),
      anonymousFlg: isAnonymous,
    }
    setIsLoading(true)

    QuestionControllerService.createQuestion(questionForm)
      .then(() => {
        toast.success('登録しました')
        closeModal()
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }
  return (
    <>
      <div className="question-form">
        <p className="form__item form__item--mt-large">
          <label className="form__label">
            質問内容
            <textarea
              className="form__textarea"
              name="description"
              placeholder="例）犬と猫どちらが好きですか？"
              value={questionValue}
              onChange={handleQuestionChange}
              maxLength={140}
            ></textarea>
          </label>
        </p>
        {answerValues.map((value, index) => (
          <div key={index} className="form__item">
            <label className={`form__label`}>
              選択肢{index + 1}
              <input
                type="text"
                value={value}
                onChange={event => handleAnswerChange(index, event)}
                placeholder="選択肢を入力してください"
                maxLength={25}
              />
            </label>
          </div>
        ))}
        {answerValues.length < 4 && (
          <Button
            className="btn btn__secondary"
            imageUrl="/images/icon_add.svg"
            onClick={addAnswer}
          >
            選択肢を追加
          </Button>
        )}
        <p className="form__check">
          <input
            type="checkbox"
            id="anonymous-post"
            name="anonymous-post"
            checked={isAnonymous}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="anonymous-post">匿名で質問する</label>
        </p>
      </div>
      <>
        <div className="modal-edit-btn__wrapper send-cube__btns">
          <Button className="btn_secondary-l" onClick={closeModal}>
            キャンセル
          </Button>
          <Button
            className="btn"
            disabled={isEmptyForm}
            onClick={createQuestion}
          >
            投稿する
          </Button>
        </div>
      </>
    </>
  )
}
