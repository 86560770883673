import React from 'react'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'

type NavItemProps = {
  label: string
  href?: string
  iconUrl?: string
  iconPath?: string
  className?: string
  count?: number
  prefix?: string | string[]
  onClick?: () => void
  isMobile?: boolean
}

export const NavItem: React.FC<NavItemProps> = ({
  label,
  href,
  iconUrl,
  iconPath,
  className,
  count = 0,
  prefix,
  onClick,
  isMobile = false,
}) => {
  const location = useLocation()
  const isHome = href === '/' && location.pathname === '/'

  const iconStyle =
    href === '/notification' || href === '#'
      ? 'translate-x-[2px] translate-y-[2px]'
      : ''

  const isActive = isHome
    ? isHome
    : Array.isArray(prefix)
    ? prefix.some(p => location.pathname.startsWith(p))
    : location.pathname === href ||
      (prefix && location.pathname.startsWith(prefix))

  const isQuestion = href === '/question'

  return (
    <li
      className={
        isMobile ? `w-1/4 ${className}` : `text-sm font-bold ${className}`
      }
    >
      {!isMobile ? (
        <a
          href={href}
          className={clsx(
            'relative block w-full h-full text-gray-900 text-sm font-bold rounded-lg py-3 pl-12 pr-4 transition ease-out duration-300',
            isActive
              ? 'bg-gray-100 opacity-100'
              : 'opacity-60 hover:shadow-md hover:opacity-60',
          )}
        >
          {iconUrl && (
            <span
              className="absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 bg-no-repeat bg-center"
              style={{ backgroundImage: `url(${iconUrl})` }}
            ></span>
          )}
          {label}
          {count > 0 && (
            <span className="absolute block min-w-[24px] w-auto h-[19px] top-1/2 right-[16px] transform -translate-y-1/2 rounded-full bg-[#f0860a] text-white font-semibold text-[10px] px-[6px] text-center">
              {count > 99 ? '99+' : count}
            </span>
          )}
        </a>
      ) : (
        <a
          href={href}
          className="flex flex-col justify-center items-center w-full h-full relative p-1"
          onClick={onClick}
        >
          <svg
            width={isQuestion ? '18' : '30'}
            height="30"
            viewBox={isQuestion ? '0 0 18 17' : '0 0 30 30'}
            fill="none"
            className={clsx(
              isActive ? 'fill-[#212643]' : 'fill-Gray-light',
              iconStyle,
            )}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fillRule="evenodd" d={iconPath} />
          </svg>

          <span
            className={`text-[10px] block ${
              isActive ? 'text-[#212643]' : 'text-[#8a8f9f]'
            }`}
          >
            {label}
            {count > 0 && (
              <span className="absolute block min-w-[13px] w-auto h-[13px] top-[16%] right-[31%] transform -translate-y-1/2 rounded-full bg-[#f0860a] text-white font-semibold text-[7px] p-[1.5px_3px] text-center">
                {count > 99 ? '99+' : count}
              </span>
            )}
          </span>
        </a>
      )}
    </li>
  )
}
