import React, { FC, useState } from 'react'
import {
  MemberControllerService,
  MemberQuery,
  MemberUpdateForm,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import Loading from 'react-loading'
import { toast } from 'react-toastify'
import { ImageUploadModal } from '../imageUploadModal'
import { useModal } from '../../contexts/modalContext'
import { Button } from '../../components/button'

interface Props {
  member: MemberQuery
  closeModal: () => void
}

export const ProfileEditModal: FC<Props> = ({ member, closeModal }) => {
  const { openModal } = useModal()

  const [memberName, setUserName] = useState(member.memberName)
  const [memberNameFurigana, setUserNameFurigana] = useState(
    member.memberNameFurigana || '',
  )
  const [positionName, setPositionName] = useState(
    member.position?.positionName,
  )
  const [singleWord, setSingleWord] = useState(member.singleWord)
  const [image, setImage] = useState(member.profileImageUrl)
  const [isLoading, setIsLoading] = useState(false)

  const update = () => {
    setIsLoading(true)
    MemberControllerService.updateMember({
      memberName: memberName,
      memberNameFurigana: memberNameFurigana,
      positionName: positionName,
      singleWord: singleWord,
      profileImageUrl: image,
    } as MemberUpdateForm)
      .then(() => {
        closeModal()
        toast.success('プロフィールを更新しました')
      })
      .catch(err => {
        apiErrorHandler(err)
        setIsLoading(false)
      })
  }

  const changeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value)
  }

  const changeUserNameFurigana = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUserNameFurigana(event.target.value)
  }

  const changePositionName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPositionName(event.target.value)
  }

  const changeSingleWord = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // 100文字制限
    if (event.target.value.length > 100) {
      return
    }
    setSingleWord(event.target.value)
  }

  return (
    <div className="profile-edit__content">
      {isLoading ? (
        <Loading className="loading" type="spin" color="#007559" />
      ) : (
        <>
          <div className="profile-edit__head">
            <div
              className="profile-edit__img"
              onClick={() => openModal(ImageUploadModal, { image, setImage })}
            >
              <img
                src={image ? image : '/icons/avatar-sample.png'}
                alt="プロフィール画像"
                width={64}
                height={64}
              />
            </div>
            {/*
        <button type="button" className="profile-edit__btn">
          カバー画像を変更
        </button>
        */}

            <Button
              className="profile-edit-page_icon_clear modal-close"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                aria-label="Close modal"
              >
                <rect width="24" height="24" rx="12" fill="#EDF0F7" />
                <path
                  d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
                  fill="#8A8F9F"
                />
              </svg>
            </Button>
          </div>

          <div className="profile-edit__body">
            <dl className="profile-edit__list">
              <div className="profile-edit-list__item">
                <dt className="profile-edit__term">お名前</dt>

                <dd className="profile-edit__desc">
                  <input
                    type="text"
                    value={memberName}
                    onChange={changeUserName}
                  />
                </dd>
              </div>

              <div className="profile-edit-list__item">
                <dt className="profile-edit__term">ふりがな</dt>
                <dd className="profile-edit__desc">
                  <input
                    type="text"
                    value={memberNameFurigana}
                    onChange={changeUserNameFurigana}
                  />
                </dd>
              </div>

              <div className="profile-edit-list__item">
                <dt className="profile-edit__term">役職</dt>

                <dd className="profile-edit__desc">
                  <input
                    name="group"
                    placeholder="例）プロダクト企画本部 / parksグループ / UI/UXデザイナー"
                    value={positionName}
                    onChange={changePositionName}
                  ></input>
                </dd>
              </div>

              <div className="profile-edit-list__item">
                <dt className="profile-edit__term">ひとこと（100文字）</dt>

                <dd className="profile-edit__desc">
                  <textarea
                    placeholder="興味のあるトピックを教えてください"
                    maxLength={100}
                    value={singleWord}
                    onChange={changeSingleWord}
                  ></textarea>
                </dd>
              </div>
            </dl>
            <div className="modal-edit-btn__wrapper">
              <Button
                className="btn_secondary-l btn_modal-edit_cancel"
                onClick={closeModal}
              >
                キャンセル
              </Button>
              <Button className="btn btn_modal-edit_save" onClick={update}>
                保存
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
